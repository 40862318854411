import { render, staticRenderFns } from "./collection.vue?vue&type=template&id=dce56104&scoped=true"
import script from "./collection.vue?vue&type=script&lang=js"
export * from "./collection.vue?vue&type=script&lang=js"
import style0 from "./collection.vue?vue&type=style&index=0&id=dce56104&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dce56104",
  null
  
)

export default component.exports