<template>
    <div class="community-collection-page">
        <!-- <div class="label-container">
      <div class="w label-wrapper">
        <div
          class="arrow-left"
          @click="handleOffset(2)"
          v-show="isLeftArrowShow"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="label-box" ref="labelBox">
          <ul class="label-list" ref="labelListWrapper">
            <li
              class="label-item"
              v-for="item in labelList"
              :key="item.id"
              :style="{
                'background-color': item.bgColor,
              }"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
        </div>
        <div
          class="arrow-right"
          @click="handleOffset(1)"
          v-show="isRightArrowShow"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div> -->
        <div class="works-content" v-if="hasAccess">
            <div class="works-content-top-container">
                <div class="w works-content-top">
                    <div class="user-container" @click.stop="gotoDesigner">
                        <el-avatar :size="38" :src="folderInfo.photo || circleUrl"></el-avatar>
                        <div class="name">{{ $lodash.phoneNuberConvert(folderInfo.nickName) }}</div>
                    </div>

                    <div class="works-title">
                        <div class="title-content">
                            <p class="title ellipsis">{{ folderInfo.name }}</p>
                            <div class="sub-title">
                                <!-- <span class="count-wrapper"> -->
                                <div v-if="folderInfo.pictureCount > 0" :title="'图片：' + folderInfo.pictureCount">
                                    <!-- <i class="iconfont icon-tupian"></i> -->
                                    <img src="../../assets/images/icon/icon_pic.png" alt="" />
                                    <span>{{ folderInfo.pictureCount }}</span>
                                </div>
                                <el-divider direction="vertical" v-if="folderInfo.pictureCount && folderInfo.modelCount"></el-divider>
                                <div v-if="folderInfo.modelCount > 0" :title="'模型：' + folderInfo.modelCount">
                                    <img src="../../assets/images/icon/icon_models.png" alt="" class="icon-model" />
                                    <span>{{ folderInfo.modelCount }}</span>
                                </div>
                                <!-- </span> -->
                            </div>
                        </div>
                        <el-popover placement="bottom" width="400" trigger="hover" popper-class="work-share-popper">
                            <div slot="reference" class="fx-wrapper">
                                <i class="iconfont icon-fenxiang"></i>
                                <!-- <i class="iconfont icon-fenxiang1"></i> -->
                            </div>
                            <div class="share-wrapper">
                                <div class="share-title">分享作品集</div>
                                <div class="share-box">
                                    <el-input v-model="shareLink"></el-input>
                                    <el-button type="success" class="copy-btn" plain @click="handleShareLink(data, $event)" id="copy"
                                        >复制链接</el-button
                                    >
                                </div>
                            </div>
                        </el-popover>
                    </div>

                    <div class="search-container">
                        <el-input
                            placeholder="本作品集内搜索"
                            prefix-icon="el-icon-search"
                            v-model="searchVal"
                            clearable
                            @clear="searchByWords"
                            @change="searchByWords"
                        >
                        </el-input>
                    </div>
                </div>
            </div>

            <div class="w works-container">
                <div class="works-wrapper" ref="cwaterfallContainer">
                    <HWaterfall
                        ref="cwaterfallWrapper"
                        :col="maxCols"
                        :width="225"
                        :gutterWidth="13"
                        :data="waterfallList"
                        @scroll="myScroll"
                        @loadmore="getData"
                        :lazyDistance="0"
                        :loadDistance="100"
                        @finish="handleFinish"
                    >
                        <template>
                            <div
                                class="cell-item hover-item"
                                v-for="(item, index) in waterfallList"
                                :key="index"
                                @click="handleViewImage(item, index)"
                            >
                                <img
                                    v-if="item.obsThumbUrl"
                                    :src="item.obsThumbUrl"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width"
                                    :data-height="item.height"
                                />
                                <img
                                    v-else-if="item.type == 1"
                                    src="@/assets/images/coverdefault.png"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width - 4"
                                />
                                <img
                                    v-else-if="item.type == 0"
                                    src="@/assets/images/image_cover_default.png"
                                    alt=""
                                    class="img"
                                    fit="contain"
                                    data-key="mainImg"
                                    :data-width="item.width - 4"
                                />
                                <div class="img-error" v-else>
                                    <i class="iconfont icon-zanwutupian" style="font-size: 100px"></i>
                                </div>

                                <div class="operation-container" :style="{ position: 'absolute', bottom: 0, color: '#fff' }">
                                    <div class="opt-btns opt-btns-name">
                                        <el-tooltip class="item" effect="dark" :content="item.url" placement="top-start">
                                            <span class="opt-name" style="cursor: pointer" @click="handleOpenUrl(item.url)">
                                                {{ item.url }}
                                            </span>
                                        </el-tooltip>
                                    </div>
                                    <div class="opt-btns-right">
                                        <div class="icon-wrapper" @click.stop="handleDownloadPic(item)">
                                            <img src="../../assets/images/icon/icon_download.png" alt="" />
                                        </div>
                                        <div v-if="item.userId != userId">
                                            <el-tooltip effect="dark" content="喜欢" placement="bottom">
                                                <div style="cursor: pointer" @click.stop="handlePoint(item)">
                                                    <i class="iconfont icon-aixinshixin" :class="{ active: item.hasPoint }"></i>
                                                </div>
                                            </el-tooltip>
                                        </div>
                                        <div v-if="item.userId != userId">
                                            <el-tooltip effect="dark" content="保存到工作台" placement="bottom">
                                                <div style="cursor: pointer" @click.stop="handleSaveToWorkSpace(item)">
                                                    <i class="el-icon-document"></i>
                                                    <!-- <span class="txt">保存到工作台</span> -->
                                                </div>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </div>

                                <!-- 模型标记 -->
                                <div class="model-flag" v-if="item.type == 1">
                                    <img src="../../assets/images/icon/icon_models.png" alt="" />
                                </div>
                                <!-- 图片尺寸 -->
                                <div class="size" v-if="item.type == 0">
                                    {{ item.width + "x" + item.height }}
                                </div>
                            </div>
                        </template>
                    </HWaterfall>
                </div>
                <Empty v-if="isEmpty" description="未找到相似作品" />
            </div>
        </div>
        <!-- 无权限提示模块 -->
        <div class="w access-wrapper" v-else>
            <img src="../../assets/images/icon/icon_user.png" alt="" />
            <h2>抱歉，您无权查看文件</h2>
            <p>您要浏览的文件处于私密状态或者已被删除</p>
            <div class="btn-box">
                <el-button @click="$router.push('/community')" type="primary" size="small">返回社区</el-button>
            </div>
        </div>
        <el-dialog :visible.sync="dialogShow" :close-on-click-modal="false" custom-class="work-info-dialog" :before-close="handleBeforeClose">
            <info-dialog :id="imgId"></info-dialog
        ></el-dialog>
    </div>
</template>

<script>
import { downPic } from "@/libs/tools";
import infoDialog from "./component/infoDialog.vue";
import Clipboard from "clipboard";
import { previewModel, downloadModelV2, new_downloadModel } from "@/util/model";
import { Loading } from "element-ui";
export default {
    components: {
        infoDialog
    },
    data() {
        return {
            loadMore: true,
            diff: false,
            SUCCESS_CODE: 0,
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            labelList: [
                {
                    id: 1,
                    name: "中式庭院",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 2,
                    name: "现代艺术",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 3,
                    name: "工艺制作",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 4,
                    name: "雕塑",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 5,
                    name: "橱窗",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 6,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 7,
                    name: "家具",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 8,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 9,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 10,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 11,
                    name: "家具",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 12,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                },
                {
                    id: 13,
                    name: "装饰设计",
                    bgColor:
                        "rgb(" +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        "," +
                        Math.floor(Math.random() * 200 + 55) +
                        ")"
                }
            ],
            isLeftArrowShow: false,
            isRightArrowShow: false,
            waterfallList: [],
            pageParams: {
                pageSize: 50,
                pageNum: 0,
                total: 0,
                currentPage: 0
            },
            pageLoading: false,
            isEmpty: false,
            imgDataInfo: {},
            maxCols: 5,
            folderInfo: {}, // 文件夹详情
            id: "", // 图片集id
            dialogShow: false,
            imgId: 0,
            searchVal: "",
            shareLink: "", // 分享链接
            type: "",
            userId: "",
            loadingInstance: null,
            hasAccess: true
        };
    },
    watch: {
        "$route.query.type": {
            handler(val) {
                this.type = val;
                // this.getData(true);
            },
            immediate: true,
            deep: true
        }
    },
    created() {
        this.shareLink = window.location.href;
        this.type = this.$route.query.type ? this.$route.query.type : "";
        this.id = this.$route.params.id;
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        this.userId = userInfo.id;
        this.getFolderInfo(this.id);
        this.getData(true);
        this.getTagList();
    },
    mounted() {
        // this.caculateCol();
        let self = this;
        window.onresize = function () {
            self.caculateCol();
        };
    },
    methods: {
        handleDownloadPic(item) {
            this.pageLoading = true;
            let ids = item.id;
            console.log(item);
            this.$http
                .post("/download/download", null, { params: { ids } })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        if (item.type == 0) {
                            downPic(item.obsUrl, item.name, item.format);
                        } else if (item.type == 1) {
                            if (item.objectId) {
                                downloadModelV2(item.objectId, item.name);
                            } else {
                                new_downloadModel(item.realiboxSceneId, item.name);
                            }
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.pageLoading = false;
                })
                .catch(err => {
                    this.$message.error(err);
                    this.pageLoading = false;
                });
        },
        handleOpenUrl(url) {
            url && window.open(url);
        },
        caculateCol() {
            // console.log(this.$refs.cwaterfallContainer);
            let width = this.$refs.cwaterfallContainer.clientWidth;
            //   console.log(width);
            this.maxCols = parseInt(width / 225);
        },
        myScroll(res) {
            if (res.diff <= 100) {
                console.log("qty diff 加载");
                this.diff = true;
                this.getData();
            } else {
                this.diff = false;
            }
        },
        // 获取图片集资源
        getData(flag) {
            if (!this.loadMore) return;
            this.loadMore = false;
            console.log("loadmore");
            if (flag) {
                this.waterfallList = [];
                this.pageParams = {
                    pageSize: 50,
                    pageNum: 0,
                    total: 0,
                    currentPage: 0
                };
            }
            if (this.pageParams.currentPage * this.pageParams.pageSize > this.pageParams.total) {
                // this.$refs.waterfall.waterfallOver();
                return;
            }
            this.loadingInstance = this.$loading({
                fullscreen: true,
                background: "rgba(0,0,0,.3)"
            });
            this.pageLoading = true;
            this.pageParams.pageNum++;
            let params = Object.assign({}, this.pageParams);
            params.id = this.id;
            params.search = this.searchVal;
            params.type = this.type;
            this.$http
                .get("/resource-info/getDirRes", { params })
                .then(res => {
                    if (res.code == 0) {
                        let data = res.data;
                        data.records.forEach(item => {
                            item.checked = false;
                        });
                        this.pageParams.total = data.total;
                        this.totalImage = data.total;
                        this.pageParams.currentPage = data.current;
                        if (data.records.length) {
                            this.waterfallList = this.waterfallList.concat(data.records);
                        }
                        // this.$waterfall.forceUpdate();
                        if (data.records.length === 0) {
                            this.pageLoading = false;
                            this.loadingInstance.close();
                        }
                        if (this.waterfallList.length === 0) {
                            this.isEmpty = true;
                        }
                    } else {
                        this.pageLoading = false;
                        this.loadingInstance.close();
                    }
                })
                .catch(err => {
                    this.waterfallList = [];
                    this.isEmpty = true;
                    this.pageLoading = false;
                    this.loadingInstance.close();
                });
        },
        // 瀑布流元素渲染完成
        handleFinish() {
            console.log("----渲染完成1----");
            this.loadMore = true;
            // this.isLoad = false;
            this.pageLoading = false;
            this.loadingInstance.close();
            const state = this.scrollToTheEnd();
            if (this.diff && state) {
                this.getData();
            }
        },
        scrollToTheEnd() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollHeight > clientHeight && scrollTop + clientHeight >= scrollHeight - 100) {
                return true;
            }
            return false;
        },
        handleOffset(type) {
            // console.log(this.$refs.labelBox.clientWidth);
            let boxWidth = this.$refs.labelBox.clientWidth;
            let wrapperWidth = this.$refs.labelListWrapper.clientWidth;
            let offsetWidth = wrapperWidth - boxWidth;
            let left = this.$refs.labelListWrapper.style.left;
            let leftWidth = Number(left.substring(0, left.length - 2));
            console.log(leftWidth);
            console.log(offsetWidth);
            //   type为1是向左移动，2是向右移动
            if (type == 1) {
                this.isLeftArrowShow = true;
                if (Math.abs(leftWidth) < offsetWidth - 100) {
                    this.$refs.labelListWrapper.style.left = leftWidth - 100 + "px";
                } else {
                    this.$refs.labelListWrapper.style.left = -offsetWidth + "px";
                }
            } else {
                if (leftWidth > 0) {
                    this.$refs.labelListWrapper.style.left = leftWidth - 50 + "px";
                } else {
                    this.$refs.labelListWrapper.style.left = 0 + "px";
                    this.isLeftArrowShow = false;
                }
            }
        },
        // 获取标签列表
        getTagList() {
            this.$http.get("/com-tag-info/list").then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    let data = res.data;
                    data.forEach(item => {
                        item.bgColor =
                            "rgb(" +
                            Math.floor(Math.random() * 200 + 55) +
                            "," +
                            Math.floor(Math.random() * 200 + 55) +
                            "," +
                            Math.floor(Math.random() * 200 + 55) +
                            ")";
                    });
                    this.labelList = data;
                }
            });
        },
        // 标签筛选
        handleTagScreen(id) {
            this.tid = id;
            this.getData(true);
        },
        // 查询图集详情
        getFolderInfo(id) {
            this.$http
                .get("/directory-info/comDireInfo/" + id)
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        this.hasAccess = true;
                        this.folderInfo = res.data;
                    } else {
                        this.hasAccess = false;
                    }
                })
                .catch(err => {
                    this.hasAccess = false;
                });
        },
        // 前往设计师页面
        gotoDesigner() {
            if (this.folderInfo.userId) {
                let routeData = this.$router.resolve({
                    path: "/community/designer/" + this.folderInfo.userId
                });
                window.open(routeData.href, "_blank");
            }
        },
        // 给图片点赞
        handlePoint(data) {
            let params = {
                id: data.id,
                status: data.hasPoint ? 0 : 1
            };
            this.$http.post("/point/modify", null, { params }).then(res => {
                if (res.code == this.SUCCESS_CODE) {
                    // 改变状态
                    data.hasPoint = !data.hasPoint;
                }
            });
        },
        // 保存下载记录
        saveDownloadRecords(item) {
            this.pageLoading = true;
            let ids = item.id;
            this.$http
                .post("/download/download", null, { params: { ids } })
                .then(res => {
                    if (res.code == this.SUCCESS_CODE) {
                        downPic(item.obsUrl, item.name);
                    } else {
                        this.$message.error(res.msg);
                    }
                    this.pageLoading = false;
                })
                .catch(err => {
                    this.$message.error(err);
                    this.pageLoading = false;
                });
        },
        // 下载图片
        downLoadImage(item) {
            this.saveDownloadRecords(item);
        },
        handleViewImage(data, index) {
            if (data.type == 1) {
                previewModel(data.id, data.objectId);
            } else {
                let routeData = this.$router.resolve({
                    path: `/community/imageDetail/${data.id}?pageNum=${Math.floor(index / 50) + 1}&id=${this.id}`
                });
                window.open(routeData.href, "_blank");
            }
        },
        handleBeforeClose(done) {
            // this.dialogShow = false;
            // this.imgId = 0;
            done();
        },
        // 搜索作品
        searchByWords(val) {
            this.searchVal = val;
            this.getData(true);
        },
        // 保存到工作台
        handleSaveToWorkSpace(item) {
            console.log("保存到工作台！");
            let params = { rid: item.id };
            this.$http
                .post("/resource-info/collect", null, { params })
                .then(res => {
                    if (res.code == 0) {
                        this.$message.success("操作成功！");
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch(err => {
                    this.$message.error("操作失败！");
                });
        },
        handleShareLink(data, e) {
            // let linkUrl =
            //   window.location.origin +
            //   window.location.pathname +
            //   "#/community/collection/" +
            //   data.id;
            let linkUrl = window.location.href;
            let clipboard = new Clipboard(e.target, { text: () => linkUrl });
            clipboard.on("success", () => {
                this.$message.success("已复制分享链接");
                // 释放内存
                clipboard.destroy();
            });
            clipboard.on("error", e => {
                console.log("复制失败", e);
                // 释放内存
                clipboard.destroy();
            });
            clipboard.onClick(e);
        }
    }
};
</script>

<style scoped>
.img-url {
    font-size: 16px;
    width: 100px;
    position: absolute;
    bottom: 0;
    color: #fff;
    z-index: 2;
}
</style>
